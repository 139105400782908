/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';



html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.p-datatable>.p-datatable-wrapper {
    overflow: inherit !important;
}